import React from 'react';
import styled from 'styled-components';
import { Box, Typography, Link } from '@material-ui/core';

const Kontakt = () => (
  <ContentContainer>
    <Box marginTop="37px">
      <Typography variant="h2">Kontakt</Typography>
    </Box>
    <Box margin="28px 0">
      <Typography variant="subtitle1">
        Har du spørgsmål, eller har brug for yderligere oplysninger om Min
        Klimaberegner, er du altid velkommen til at kontakte os på:{' '}
        <Link href="mailto:info@mailreal.dk" target="_blank" rel="noopener">
          info@mailreal.dk
        </Link>{' '}
        eller{' '}
        <Link href="mailto:kontakt@domutech.dk" target="_blank" rel="noopener">
          kontakt@domutech.dk
        </Link>
      </Typography>
    </Box>
  </ContentContainer>
);

const ContentContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
`;

export default Kontakt;
