// @flow
import React, { Component } from 'react';
import styled, { css, keyframes } from 'styled-components';
// import { Transition } from 'react-transition-group';
import { Fab, withStyles, Box, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

type Props = {
  onClose: any,
  classes: any,
  content: string,
};

type State = {
  isClosing: boolean,
};

type Answer = {
  title: string,
  subtitle: string,
};

type Questions = Array<Answer>;

export const TEMP_QUESTIONS: Questions = [
  {
    title: 'Hvad er Klimaberegner?',
    subtitle: 'Klimaberegner er et digitalt værktøj, der…',
    text:
      'Klimaberegner er et digitalt værktøj, der guider dig til en mere klimavenlig bolig ved at præsentere forbedringsforslag fra boligens energimærke på en ny og visuel måde.',
  },
  {
    title: 'Hvordan bruges Klimaberegner?',
    subtitle: 'Du indtaster en adresse, hvorefter…',
    text:
      'Du indtaster en adresse, hvorefter Klimaberegner henter data fra boligens energimærke. Du vælger herefter et af de rentable forbedringsforslag for at se, hvad du og klimaet vinder på, at du gennemfører forbedringen.',
  },
  {
    title: 'Hvorfor har min bolig ikke et energimærke?',
    subtitle: 'Energimærker bliver typisk lavet…',
    text:
      'Energimærker bliver typisk lavet i forbindelse med boligsalg. Så hvis Klimaberegner ikke kan finde et energimærke, kan det være fordi, at boligen er købt før energimærket blev lovpligtigt i forbindelse med salg i 2010. Det kan også skyldes, at boligen ikke er omfattet af energiloven og derfor ikke behøver et energimærke. Hvis du ved, at der for nylig er blevet udarbejdet et energimærke, kan du evt. prøve Klimaberegner igen på et senere tidspunkt.',
  },
  {
    title: 'Hvem har lavet Klimaberegner?',
    subtitle: 'Klimaberegner er udviklet af…',
    text:
      'Klimaberegner er udviklet af HUSSYN.PRO i samarbejde med RealMæglerne.',
  },
  {
    title: 'Hvor kommer dataen fra?',
    subtitle: 'Klimaberegner henter boligens rentable…',
    text:
      'Klimaberegner henter boligens rentable forbedringsforslag og anden data fra boligens energimærke.',
  },
  {
    title: 'Hvorfor virker det ikke på min ejendom?',
    subtitle: 'Klimaberegner kan kun benyttes på…',
    text:
      'Klimaberegner kan kun benyttes på ejendomme med et gyldigt energimærke. Kan Klimaberegner ikke finde boligens data, kan det skyldes, at boligen ikke har et energimærke, at boligen ikke har et gyldigt energimærke eller at boligens energimærke ikke indeholder nogen rentable forbedringsforslag.',
  },
  {
    title: 'Hvor meget CO2 binder et træ?',
    subtitle: 'Der er stor forskel på, hvor…',
    text:
      'Der er stor forskel på, hvor meget CO2 et træ kan binde. Mængden afhænger bl.a. af træsorten, størrelsen og rumtætheden. Til Klimaberegner har vi, i samarbejde med professor Palle Madsen fra Skovskolen, Københavns Universitet, valgt at basere vores beregning ud fra, hvor meget CO2 et gennemsnitligt bøgetræ binder om året.',
  },
];

const styles = () => ({
  closeButton: {
    backgroundColor: '#F4F4F8',
    height: '50px',
    transition: 'all 0.25s ease-in-out',
    boxShadow: '0 5px 5px 0 rgba(0, 0, 0, 0.12)',
    width: '50px',
    '&:hover': {
      backgroundColor: '#e0e0e0',
      marginTop: '-10px',
      height: '50px',
      width: '50px',
    },
  },
});

class FaqModal extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isClosing: false,
    };
  }

  onClickClose = () => this.setState({ isClosing: true });

  render() {
    const { isClosing } = this.state;
    const { classes, onClose, content } = this.props;

    return (
      <Backdrop>
        <Container
          isClosing={isClosing}
          onAnimationEnd={isClosing ? onClose : null}
        >
          <Box margin="28px">
            <Typography variant="subtitle1">{content || null}</Typography>
          </Box>
          <CloseModalGradientBackground />
          <CloseModalContainer isClosing={isClosing}>
            <Fab
              aria-label="close modal"
              onClick={this.onClickClose}
              className={classes.closeButton}
            >
              <CloseIcon />
            </Fab>
          </CloseModalContainer>
        </Container>
      </Backdrop>
    );
  }
}

// const scaleDown = keyframes`
//   from { transform: scale(1) }
//   to { transform: scale(0) }
// `;

const slideUp = keyframes`
  0% {
    bottom: -120%;
  }
  100% {
    bottom: 0;
  }
`;

const slideDown = keyframes`
  0% {
    bottom: 0;
  }
  100% {
    bottom: -120%;
  }
`;

const Backdrop = styled.div`
  position: fixed;
  z-index: 1300;
  right: 0px;
  bottom: 0px;
  top: 0px;
  left: 0px;
`;

const Container = styled.div`
  animation: ${props => (props.isClosing ? slideDown : slideUp)} 0.35s
    ease-in-out 0s forwards;
  background-color: #fff;
  display: flex;
  justify-content: center;
  overflow-y: auto;
  height: 100%;
  width: 100%;
  z-index: 1300;
`;

// const ContentContainer = styled.div`
//   padding: 0 25px 140px 25px;
// `;

// const Separator = styled.div`
//   background-color: #e8e8eb;
//   margin-top: 33px;
//   height: 1px;
//   width: 100%;
// `;

const CloseModalContainer = styled.div`
  ${props =>
    props.isClosing
      ? css`
          animation: ${slideDown} 0.35s ease-in-out 0s forwards;
        `
      : ''}
  align-self: center;
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  position: fixed;
  height: 140px;
`;

const CloseModalGradientBackground = styled.div`
  background: rgb(255, 255, 255);
  background: -moz-linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  background: -webkit-linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  bottom: 0;
  pointer-events: none;
  position: fixed;
  height: 140px;
  width: 100%;
`;

export default withStyles(styles)(FaqModal);
