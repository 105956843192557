// @flow
import React from 'react';
import { useRoutes, navigate } from 'hookrouter';
import { HomePage, LocationResultDetailsPage } from './pages';

import './App.css';
import TreesBagsProportion from './pages/trees-bags-proportion';

function App() {
  const navigateDetail = id => {
    navigate(`/detail/${id}`);
  };
  // const navigateHomepage = () => {
  //   navigate('/');
  // };

  const routeResults = useRoutes({
    '/': () => <HomePage navigateDetail={navigateDetail} />,
    '/trees': () => <TreesBagsProportion />,
    '/detail/:id': ({ id }) => {
      return <LocationResultDetailsPage id={id} />;
    },
  });

  return (
    <>
      <div className="App">
        {routeResults}
        <div id="app-modals" />
      </div>
    </>
  );
}

export default App;
