// @flow
import React, { useState, createElement } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { Fab, Menu, MenuItem, Modal, withStyles } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import CloseIcon from '@material-ui/icons/Close';
import {
  Hint,
  Kontakt,
  OmKlimaberegner,
  VilkarOgBetingelser,
  Pressekit,
} from '../faq-modal';

const MODAL_CONTENTS = {
  OM: createElement(OmKlimaberegner),
  VILKAR: createElement(VilkarOgBetingelser),
  PRESSEKIT: createElement(Pressekit),
  KONTAKT: createElement(Kontakt),
  HINT: createElement(Hint),
};

const styles = theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflowY: 'scroll',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 15),
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    width: '100%',
    boxSizing: 'border-box',
    overflowY: 'scroll',
  },
  container: {
    // padding: '10px',
  },
});

function HeaderMenu(props) {
  const { classes } = props;
  const [moreMenuOpened, setMoreMenuOpened] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState(null);

  const onMenuClick = event => {
    setMoreMenuOpened(event.currentTarget);
  };

  const onMenuClose = () => {
    setMoreMenuOpened(null);
  };

  const onMenuItemClick = contentId => () => {
    setIsModalVisible(true);
    setModalContent(contentId);
    setMoreMenuOpened(null);
  };

  const onModalClose = () => {
    setIsModalVisible(false);
    setModalContent(null);
  };

  return (
    <div className={classes.container}>
      <CustomButton onClick={onMenuItemClick('HINT')}>
        <HelpOutlineIcon fontSize="small" style={{ color: '#979797' }} />
      </CustomButton>
      <CustomButton
        aria-controls="more-options-menu"
        aria-haspopup="true"
        type="button"
        onClick={onMenuClick}
      >
        <MoreVertIcon
          style={{ color: '#979797' }}
          marginleft="18px"
          fontSize="small"
        />
      </CustomButton>
      <Menu
        id="more-options-menu"
        anchorEl={moreMenuOpened}
        keepMounted
        open={Boolean(moreMenuOpened)}
        elevation={1}
        onClose={onMenuClose}
      >
        <MenuItem onClick={onMenuItemClick('OM')}>Om Klimaberegner</MenuItem>
        <MenuItem onClick={onMenuItemClick('VILKAR')}>
          Vilkår og betingelser
        </MenuItem>
        <MenuItem onClick={onMenuItemClick('PRESSEKIT')}>Pressekit</MenuItem>
        <MenuItem onClick={onMenuItemClick('KONTAKT')}>Kontakt</MenuItem>
      </Menu>

      <Modal open={isModalVisible} className={classes.modal}>
        <div className={classes.paper}>
          {modalContent ? MODAL_CONTENTS[modalContent] : null}
          <CloseModalGradientBackground />
          <CloseModalContainer>
            <Fab
              aria-label="close modal"
              onClick={onModalClose}
              className={classes.closeButton}
            >
              <CloseIcon />
            </Fab>
          </CloseModalContainer>
        </div>
      </Modal>
    </div>
  );
}

const CustomButton = styled.button`
  background: none;
  border: 0;
  cursor: pointer;
  margin: 0;
  outline: 0;
`;

const slideDown = keyframes`
  0% {
    bottom: 0;
  }
  100% {
    bottom: -120%;
  }
`;

const CloseModalContainer = styled.div`
  ${props =>
    props.isClosing
      ? css`
          animation: ${slideDown} 0.35s ease-in-out 0s forwards;
        `
      : ''}
  align-self: center;
  align-items: center;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  position: fixed;
  height: 140px;
  width: 100%;
`;

const CloseModalGradientBackground = styled.div`
  background: rgb(255, 255, 255);
  background: -moz-linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  background: -webkit-linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  bottom: 0;
  left: 0;
  pointer-events: none;
  position: fixed;
  height: 140px;
  width: 100%;
`;

export default withStyles(styles)(HeaderMenu);
