// @flow
import React, { useState, useEffect } from 'react';
import { navigate } from 'hookrouter';
import { withStyles } from '@material-ui/styles';
import styled from 'styled-components';
import { Grid, Typography, Box } from '@material-ui/core';
import ImprovementsCard from '../improvements-card/improvements-card';
import TreeCard from '../tree-card/tree-card';
import MoneyBagCard from '../moneybag-card/moneybag-card';

const logo = require('../../images/domutech_logo.svg');

type Props = {
  data: any,
  index: number,
  currentTab: number,
  classes: any,
};

type TabPanelProps = {
  children: any,
  value: number,
  index: number,
  data: any,
};

const styles = () => ({
  content: {
    width: '100%',
    margin: '0 auto',
    overflow: 'hidden',
    backgroundColor: '#eee',
  },
  cardGrid: {
    padding: '10px 20px',
    marginTop: '0',
  },
  logo: {
    textAlign: 'center',
    padding: '40px 20px 10px',
  },
});

const improvementCardData = (data, currentTab: number) => {
  const result = {
    title: '',
    description: '',
    profitable: '',
    greenImprovementValue: 0,
  };
  if (data && data.profilt) {
    if (data.profilt[currentTab] && data.profilt[currentTab].ShortText) {
      result.title = data.profilt[currentTab].ShortText;
    }
    if (data.profilt[currentTab] && data.profilt[currentTab].profitable) {
      result.profitable = data.profilt[currentTab].profitable;
    }

    if (data.profilt[currentTab] && data.profilt[currentTab].LongText) {
      result.description = data.profilt[currentTab].LongText;
    }

    if (
      data.profilt[currentTab] &&
      data.profilt[currentTab]._greenImprovementValue
    ) {
      result.greenImprovementValue =
        data.profilt[currentTab]._greenImprovementValue;
    }
  }
  return result;
};

const treeCardData = (data, currentTab: number) => {
  const result = {
    CO2Saving: '',
  };

  if (data && data.profilt) {
    if (data.profilt[currentTab] && data.profilt[currentTab].CO2Saving) {
      result.CO2Saving = parseFloat(data.profilt[currentTab].CO2Saving).toFixed(
        2
      );
    }
  }
  return result;
};

const moneyBagData = (data, currentTab: number) => {
  const result = {
    MoneySaving: 0,
    Investment: 0,
  };

  if (data && data.profilt) {
    if (data.profilt[currentTab] && data.profilt[currentTab].MoneySaving) {
      result.MoneySaving = parseInt(data.profilt[currentTab].MoneySaving, 10);
    }

    if (data.profilt[currentTab] && data.profilt[currentTab].Investment) {
      result.Investment = parseInt(data.profilt[currentTab].Investment, 10);
    }
  }
  return result;
};

const isAllImprovementTab = (data, currentTab: number) => {
  let result = false;
  if (
    data &&
    data.profilt &&
    data.profilt[currentTab] &&
    data.profilt[currentTab]._isAllTab === true
  ) {
    result = true;
  }
  return result;
};

const isAllTabProfitable = (data, currentTab: number) => {
  let result = false;
  if (
    data &&
    data.profilt &&
    data.profilt[currentTab] &&
    data.profilt[currentTab].profitable === 'true'
  ) {
    result = true;
  }
  return result;
};

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return value !== index ? null : (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      style={{
        margin: '0 auto',
      }}
      {...other}
    >
      <Box alignItems="center" display="flex" flexDirection="column">
        {children}
      </Box>
    </Typography>
  );
}

const LocationDetailsTab = (props: Props) => {
  const { index, currentTab, data, classes } = props;
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowButton(true);
    }, 1650);
  }, []);

  const {
    title,
    description,
    greenImprovementValue,
    profitable,
  } = improvementCardData(data, currentTab);
  const { CO2Saving } = treeCardData(data, currentTab);
  const { MoneySaving, Investment } = moneyBagData(data, currentTab);
  const allImprovements = isAllImprovementTab(data, currentTab);
  const allTabProfitable = isAllTabProfitable(data, currentTab);

  return (
    <TabPanel
      key={`tabpanel-${currentTab}`}
      data={data}
      value={currentTab}
      index={index}
    >
      <div className={classes.content}>
        <Grid
          className={classes.cardGrid}
          key={`${data.id}-${currentTab}`}
          container
          direction="row"
          justify="space-between"
          spacing={2}
        >
          <Grid item key="improvement-card" xs={12} md={4}>
            <ImprovementsCard
              key={currentTab}
              title={title}
              description={description}
              profitable={profitable}
              greenImprovementValue={greenImprovementValue}
            />
          </Grid>
          <Grid item key="saved-trees" xs={12} md={4}>
            <TreeCard
              key={currentTab}
              CO2Saving={CO2Saving}
              allTabProfitable={allTabProfitable}
              allImprovements={allImprovements}
            />
          </Grid>
          <Grid item key="money-bag" xs={12} md={4}>
            <MoneyBagCard
              key={currentTab}
              profitable={profitable}
              allTabProfitable={allTabProfitable}
              MoneySaving={MoneySaving}
              Investment={Investment}
              allImprovements={allImprovements}
            />
          </Grid>
        </Grid>

        <div className={classes.logo}>
          <img src={logo} alt="Boligtech Logo" width="250px" />
        </div>
      </div>
    </TabPanel>
  );
};

export default withStyles(styles)(LocationDetailsTab);
